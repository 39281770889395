import $ from 'jquery';
import 'jquery-ui/ui/effect';
import '../../plugins/semantic-search';

export default class Home {
  constructor() {
    this.companiesJSON = null;
    this.eventsLoaded = false;
    this.init();
  }

  init() {
    $.getJSON(`${window.SITE_URL}situations.json`, this.onDataReceived);
  }

  onDataReceived(data) {
    this.situationsJSON = data.data;
    const content = [];
    this.situationsJSON.forEach((situation) => {
      content.push({ title: situation.title, url: situation.url });
    });

    $('.ui.search.situations-search')
      .search({
        source: content,
        error: {
          source: 'Cannot search. No source used, and Semantic API module was not included',
          noResults: 'Dit product hoort niet thuis in de riolering. Meer info over hoe sorteren vind je via <a href="https://www.betersorteren.be/" target="_blank" rel="noopener noreferrer">deze link</a>.',
          logging: 'Error in debug logging, exiting.',
          noTemplate: 'A valid template name was not specified.',
          serverError: 'There was an issue with querying the server.',
          maxResults: 'Results must be an array to use maxResults setting',
          method: 'The method you called is not defined.',
        },
      });
  }
}
